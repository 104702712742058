<template>
<div>
  <!-- guest sidebar : left -->
  <b-sidebar id="sidebar-1" shadow>
      <div class="px-3 py-2">
        <nav class="mb-2">
            <b-nav vertical pills>
              <b-nav-item href="/guest" exact active-class="active">Main</b-nav-item>
              <b-nav-item href="/guest/events/create" exact active-class="active" v-if="$app.menuItems.includes('events')">Create Event</b-nav-item>
              <b-nav-item href="/guest/events" exact active-class="active" v-if="$app.menuItems.includes('events')">Events</b-nav-item>
              <b-nav-item href="/guest/profile" exact active-class="active" v-if="user">Profile</b-nav-item>
              <b-nav-item href="/guest/matches" exact active-class="active" v-if="$app.menuItems.includes('matches')">Matches</b-nav-item>
              <b-nav-item href="/guest/account" exact active-class="active" v-if="$app.menuItems.includes('account')">Account</b-nav-item>
              <br/>
              <br/>
              <b-nav-item v-if="$debug.isAvailable" align="bottom" href="/guest/debug">Debug</b-nav-item>
            </b-nav>
        </nav>
      </div>
  </b-sidebar>
  <!-- Main part -->
  <div>
    <!-- Header -->
    <div class="sticky-top bg-white">
      <div class="position-absolute float-left burger-button" v-b-toggle.sidebar-1 v-if="!hideNav">
        <font-awesome-icon icon="bars"/>
      </div>
      <div class="text-center">
        <div class="p-2" style="height:42px;">
          <img v-if="!hideTitle" :srcset="$app.productLogoTitle + ' 2x'"/>
          <b-badge v-if="$app.environment" variant="success">{{ $app.environment }}</b-badge>
        </div>
      </div>
    </div>
    <!-- Body -->
    <div class="d-flex flex-row" v-if="manager">
        <!-- Left Side Bar -->
        <div class="bd-left-sidebar" v-if="showLeftSideBar">
          LEFT SIDEBAR
        </div>
        <!-- Content -->
        <div class="bd-content">
          <!-- Debug display -->
          <div v-if="$debug.isOn" class="alert-info mb-1">
            <debug-obj label="$route.meta"  :objData="$route.meta"/>
            <debug-obj label="user" :objData="user" expand folded/>
            <debug-obj label="account" :objData="account && account.toJSON()" expand folded/>
            disableContainer: {{ disableContainer }}
            <div class="form-inline sub-mr-2">
              <button class="btn btn-primary btn-sm" @click="showLeftSideBar = !showLeftSideBar">Toggle Left Sidebar</button>
              <button class="btn btn-primary btn-sm" @click="showRightSideBar = !showRightSideBar">Toggle Right Sidebar</button>
              <button class="btn btn-primary btn-sm" @click="disableContainer = !disableContainer">Toggle Disable Container</button>
            </div>
          </div>
          <!-- Router -->
          <div :class="{container:!disableContainer}">
            <router-view v-if="loaded"
              :manager="manager"
              v-on:load-user="loadUser"></router-view>
            <div v-else>
              <progress/>
            </div>
          </div>
        </div>
        <!-- Right Side Bar -->
        <div class="bd-right-sidebar d-none d-md-block" v-if="showRightSideBar">
          RIGHT SIDEBAR
        </div>
    </div>
    <!-- Footer -->
    <div class="fixed-bottom" style="pointer-events: none;">
      <div class="clearfix">
      <div class="small text-muted bg-light d-inline p-1 rounded-top" style="pointer-events: auto;">&nbsp;{{ $app.productNameLC }}{{ appVariant }}@{{ appVersion }}
        <div class="d-inline" v-if="$debug.isAvailable">
          - debug <input type="checkbox" v-model="$debug.isOn"/> - low def <input type="checkbox" v-model="$debug.lowDef"/>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getBrowser } from '@/services/utils';
import { db } from "@/services/db";
import { getLog, sentryUser } from "@/services/log";
import { serverEpochMS } from "@/services/server-time";
import debugObj from "@/components/debugObj";
let log = getLog("guest-router");

import { firebase } from "@/services/firebase";
import "firebase/auth";
let auth = firebase.auth();

export default {
  components: { 
    debugObj
  },
  data() {
    return {
      appVersion: require("@/../package.json").version,
      appVariant: "",
      hideNav: false,
      hideTitle: false,
      showLeftSideBar: false,
      showRightSideBar: false,
      disableContainer: false,

      manager: null,
      user: null,
      eventUser: null,
      account: null,

      // loading: hides router if loading needed
      loaded: true,
    }
  },
  mounted() {
    this.$app.router = this;
    document.title = this.$app.productName;
    if (getBrowser() == "live-wrapper") {
      this.appVariant = "-ios";
    }
    // Load debug
    if (this.$route.query.debug) {
      this.$debug.isAvailable = true;
      this.$debug.isOn = true;
      localStorage.setItem("debug", serverEpochMS());
    } else {
      this.$debug.isAvailable = localStorage.getItem("debug") != null;
    }
    this.bindManager();
    this.initAuth();
  },
  beforeDestroy() {
    this.AuthStateChangedListener();
  },
  methods: {
    async initAuth() {
      // Load user id
      var uid = null;
      if (this.$route.query.ignoreuid)
        log.log("Ignore localStorage userid");
      else if (this.propUid)
        uid = this.propUid;
      else if (this.$route.query.uid)
        uid = this.$route.query.uid;
      else 
        uid = localStorage.getItem('userid');
      if (uid) {
        this.loaded = false;
        log.log("id=", uid);
        this.$bind("user", db.collection("LiveUsers").doc(uid)).then(() => {
          this.loaded = true;
        });
      }
      // account
      this.AuthStateChangedListener = auth.onAuthStateChanged(user => {
        log.log("onAuthStateChanged", user);
        this.account = user;
      });
    },
    async bindManager() {
      await this.$bind("manager", db.collection("LiveEvents").doc("manager"));
      let key = `$app-${this.$app.appVariant}`;
      let $app = this.manager[key];
      if ($app) {
        log.log(`manager overrides $app with ${key}`, $app);
        this.$app = Object.assign(this.$app, $app);
      }
      if (this.$app.environment) {
        let key = `$app-${this.$app.appVariant}-${this.$app.environment}`;
        let $app = this.manager[key];
        if ($app) {
          log.log(`manager overrides $app with ${key}`, $app);
          this.$app = Object.assign(this.$app, $app);
        }
      }
    },
    // user loading callback
    async loadUser(uid, onComplete) {
      log.log(`loadUser ${uid}`);
      let user = await this.$bind("user", db.collection("LiveUsers").doc(uid));
      sentryUser({ id: this.user.id, username: this.user.name });
      localStorage.setItem("userid", this.user.id);
      localStorage.setItem("userid-time", serverEpochMS());
      log.log(`loadUser ${uid} complete`);
      onComplete(user);
    }
  }
}
</script>

<style lang="css" scoped>

.bd-left-sidebar {
  flex-basis: 320px;
  flex-grow: 0;
  flex-shrink: 0;
}

.bd-content {
  flex-grow: 1;
}

.bd-right-sidebar {
  flex-basis: 320px;
  flex-grow: 0;
  flex-shrink: 0;
}

.burger-button {
  left: 16px;
  top: 10px;
}

</style>